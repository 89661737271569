import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux/es/exports'
import {useNavigate, Link} from 'react-router-dom'

import data from 'data.json'
import text from 'text.json'
import './index.scss'
import FullScreenLoader from 'components/core/loaders/FullScreenLoader'
import {
    TickIcon,
    CrossIcon,
    ArrowUpIcon,
    InfoIcon,
    ArrowRightIcon,
    ExclamationIcon
} from 'icons/index'
import {StatuspageState, GroupComponentsStatistics, StatusTypes, HeaderState, StatuspageData} from 'utils/types'
import StatuspageService from 'api/statuspage'
import {routes} from 'utils/routes'
import {getStatuspage} from 'stores/statuspage'
import {AppDispatch} from 'stores/index'
import Pulse from 'components/core/pulse'
import CalculationService from 'utils/calculationService'
import ThemeManager from 'utils/themeManager'
import {doNothing} from 'utils/placeholder'

const StatusPage: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()
    const statuspageData = useSelector((state: StatuspageState) => state.statuspage.statuspageData) 
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const isFetching = useSelector((state: StatuspageState) => state.statuspage.isFetching)
    const updatedDate = useSelector((state: StatuspageState) => state.statuspage.updatedDate)
    const [groupComponentsStatisticsData, setGroupComponentsStatisticsData] = useState<GroupComponentsStatistics[]>([])
    const [openedItemIds, setOpenedItemIds] = useState<string[]>([])
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    const [isMediumDevice, setIsMediumDevice] = useState<boolean>(false)
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false)

    const statusTypes: StatusTypes = data.status_types
    const types = {
        group: 'group',
        component: 'component'
    }
    const autoRefreshInterval = 60000

    const allComponentsIds: string[] = statuspageData?.items?.reduce((acc: string[], item) => {
        if (item && item.type === 'component' && item.data && item.data.id) {
            acc.push(item?.data?.id)
        }
        if (item && item.data && item.data.components) {
            item.data.components.forEach((component) => {
                if (component && component.id) {
                    acc.push(component?.id)
                }
            })
        }
        return acc
    }, [])

    const getGroupComponentStatistics = async(ids: string[] = []) => {
        try {
            const promises = ids?.map(id => StatuspageService.getComponentStatistics(id))
            const res = await Promise.all(promises)
            setGroupComponentsStatisticsData(res?.map((item, index) => {
                return {
                    data: item?.data?.data,
                    id: ids[index]
                }
            }))
        } catch (e) {
            doNothing()
        }
    }

    const returnStatisticsData = (componentId: string) => {
        return isSmallDevice ?
            groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === componentId)?.data?.statistics?.slice(-30) :
            isMediumDevice ? groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === componentId)?.data?.statistics?.slice(-45) :
                groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === componentId)?.data?.statistics
    }

    const returnDaysText = () => {
        return isSmallDevice ?
            text.days_ago.replace(':value', '30') :
            isMediumDevice ? text.days_ago.replace(':value', '45') :
                text.days_ago.replace(':value', '60')
    }

    const formatMinutes = (minutes: number): string => {
        const hours: number = Math.floor(minutes / 60)
        const days: number = Math.floor(hours / 24)
        const remainingHours: number = hours % 24
        const remainingMinutes: number = minutes % 60

        const dayText: string = days === 1 ? 'day' : 'days'
        const hourText: string = hours === 1 ? 'hour' : 'hours'
        const minuteText: string = remainingMinutes === 1 ? 'minute' : 'minutes'
      
        const dayPart: string = days === 0 ? '' : `${days} ${dayText}`
        const hourPart: string = remainingHours === 0 ? '' : `${remainingHours} ${hourText}`
        const minutePart: string = remainingMinutes === 0 ? '' : `${remainingMinutes} ${minuteText}`
      
        return `${dayPart} ${hourPart} ${minutePart}`.trim()
    }

    const getOpenIncidents = (id: string) => {
        return groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === id)?.data?.open_incidents
    }

    const getGroupComponentsUptimePercent = (components: StatuspageData[]) => {
        const percentsArray = components?.map((item: StatuspageData) => groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === item?.id)?.data?.uptime?.up?.toFixed(2))
        return CalculationService.calculateAverage(percentsArray)
    }

    const handleOpenedItemIds = (id: string) => {
        if (!openedItemIds?.includes(id)) {
            setOpenedItemIds(prev => [...prev, id])
            return
        }
        const filteredOpenedItemIds = openedItemIds.filter(itemId => itemId !== id)
        setOpenedItemIds(filteredOpenedItemIds)
    }

    useEffect(() => {
        getGroupComponentStatistics(allComponentsIds)
    }, [statuspageData, updatedDate])

    useEffect(() => {
        if (windowWidth >= 768 && windowWidth < 992) {
            setIsSmallDevice(false)
            setIsMediumDevice(true)
            return
        }
        if (windowWidth < 768) {
            setIsSmallDevice(true)
            setIsMediumDevice(false)
            return
        }
        setIsMediumDevice(false)
        setIsSmallDevice(false)
    }, [windowWidth])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        const getStatuspageEveryMinute = () => {
            dispatch(getStatuspage(navigate, false, true))
        }
    
        window.addEventListener('resize', handleResize)
    
        const intervalId = setInterval(getStatuspageEveryMinute, autoRefreshInterval)
    
        return () => {
            window.removeEventListener('resize', handleResize)
            clearInterval(intervalId)
        }
    }, [])

    return isFetching ?
        <FullScreenLoader /> :
        (
            <main className="home">
                <div className="head">
                    <div className={clsx('head-img', {
                        ['incident']: statuspageData?.status !== statusTypes.operational.key
                    })}>
                        {statuspageData?.status === statusTypes.operational.key ? <TickIcon /> : <CrossIcon />}
                        <Pulse classname={statuspageData?.status === statusTypes.operational.key ? 'success' : 'error'} />
                    </div>
                    <div className="head-header">
                        <h1>
                            {statuspageData?.status === statusTypes.operational.key ? text.all_operational : text.some_incident_happen}
                        </h1>
                    </div>
                    <div className="head-description">
                        {text.last_updated_on.replace(':value', updatedDate)}
                    </div>
                </div>
                <div className="history">
                    {statuspageData?.items?.map((item, index) => {
                        return item?.type === types.component ? (
                            <div
                                key={index}
                                className={clsx('history-item', {
                                    ['incident-happen']: item?.data?.status !== statusTypes.operational.key
                                })}
                            >
                                <div className="head-container"
                                    onClick={() => handleOpenedItemIds(item?.data?.id)}
                                >
                                    <div className="log-title">
                                        {!item.data.enabled  ? 
                                            <span className='disabled-component'>
                                                <ExclamationIcon width={20} height={20} fill="#64748B"/>
                                                <p className="disabled-component-text">{text.disabled}</p>
                                            </span> :
                                            <span className={clsx('component-tick', {
                                                ['component-cross']: item?.data?.status !== statusTypes.operational.key
                                            })}>
                                                {item?.data?.status === statusTypes.operational.key ? <TickIcon width={16} height={16} /> : <CrossIcon width={16} height={16} />}
                                            </span>}
                                        <p>{item?.data?.name}</p>
                                        <div className="log-info">
                                            <InfoIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} />
                                            <p className="log-info-desc">{item?.data?.description}</p>
                                        </div>
                                    </div>
                                    <div className="log-status">
                                        <span>{`${groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === item?.data?.id)?.data?.uptime?.up?.toFixed(2)}% `}</span>
                                        {text.uptime}
                                        <span className={clsx('arrow', {
                                            ['open']: openedItemIds?.includes(item?.data?.id)
                                        })}>
                                            <ArrowUpIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} />
                                        </span>
                                    </div>
                                </div>
                                {openedItemIds?.includes(item.data.id) && <div className="progress-section">
                                    <div className="progress-bars">
                                        {returnStatisticsData(item?.data?.id)?.map((statisticData, statisticIndex) => {
                                            return statisticData?.status === null ?
                                                <div key={statisticIndex} className="progress-bar null">
                                                    <div className="no-data">
                                                        <h5>{moment(statisticData?.date).format('MMM DD, YYYY')}</h5>
                                                        <span>{text.no_data}</span>
                                                    </div>
                                                </div> : statisticData?.status !== statusTypes.operational.key ?
                                                    <div key={statisticIndex} className="progress-bar non-operational">
                                                        <div className="detect-incident">
                                                            <span className="status">
                                                                <span className="status-icon cross">
                                                                    <CrossIcon width={16} height={16} />
                                                                </span>
                                                                <span>{text.incident}</span>
                                                            </span>
                                                            <h5>{moment(statisticData?.date).format('MMM DD, YYYY')}</h5>
                                                            <p className="incident-desc">{statisticData?.description}</p>
                                                            {!!statisticData?.duration && <span className="duration">{`${text.duration}: ${formatMinutes(statisticData.duration)}`}</span>}
                                                        </div>
                                                    </div> :
                                                    <div key={statisticIndex} className="progress-bar">
                                                        <div className="no-incident">
                                                            <span className="status">
                                                                <span className="status-icon tick">
                                                                    <TickIcon width={16} height={16} />
                                                                </span>
                                                                <span>{text.operational}</span>
                                                            </span>
                                                            <h5>{moment(statisticData?.date).format('MMM DD, YYYY')}</h5>
                                                            <span className="no-incident-text">{text.no_incidents}</span>
                                                        </div>
                                                    </div>
                                        })}
                                    </div>
                                    <div className="time-interval">
                                        <h4><span>{returnDaysText()}</span> </h4>
                                        <div className="uptime">
                                            <h4><span>{`${groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === item?.data?.id)?.data?.uptime?.up?.toFixed(2)}% `}</span>{text.uptime}</h4>
                                        </div>
                                        <h4>{text.today}</h4>
                                    </div>
                                    {getOpenIncidents(item?.data?.id)?.length && (
                                        <div className="open-incident">
                                            <div className="open-incident-head">
                                                <span className="icon">
                                                    <InfoIcon stroke='#fff' />
                                                </span>
                                                <span className="text">{text.incident_details}</span>
                                            </div>
                                            <span className="open-incident-desc">
                                                {getOpenIncidents(item?.data?.id)?.[0]?.description}
                                            </span>
                                            <span className="open-incident-started">
                                                {moment(getOpenIncidents(item?.data?.id)?.[0]?.started_at).format('MMM DD, YYYY')}
                                            </span>
                                        </div>
                                    )}
                                </div>}
                            </div>
                        ) : (
                            <div
                                key={index}
                                className={clsx('history-item', {
                                    ['incident-happen']: item?.data?.status !== statusTypes.operational.key
                                })}
                            >
                                <div className="head-container"
                                    onClick={() => handleOpenedItemIds(item?.data?.id)}
                                >
                                    <div className="log-title">
                                        {item?.data?.components?.every(component => !component?.enabled)  ? 
                                            <span className='disabled-component'>
                                                <ExclamationIcon width={20} height={20} fill="#64748B"/>
                                                <p className="disabled-component-text">{text.disabled}</p>
                                            </span> :
                                            <span className={clsx('component-tick', {
                                                ['component-cross']: item?.data?.status !== statusTypes.operational.key
                                            })}>
                                                {item?.data?.status === statusTypes.operational.key ? <TickIcon width={16} height={16} /> : <CrossIcon width={16} height={16} />}
                                            </span>}
                                        <p>{item?.data?.name}</p>
                                    </div>
                                    <div className="log-status">
                                        {item?.data?.components && (
                                            <>
                                                {item?.data?.components?.every(component => !component?.enabled) ? 
                                                    text.no_active_component :
                                                    <>
                                                        <span>
                                                            {getGroupComponentsUptimePercent(item?.data?.components)}
                                                        </span>
                                                        <span>{text.uptime}</span>
                                                    </>}
                                                <span className={clsx('arrow', {
                                                    ['open']: openedItemIds?.includes(item?.data?.id)
                                                })}>
                                                    <ArrowUpIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} />
                                                </span>
                                            </>
                                        )}
                                        
                                    </div>
                                </div>
                                {
                                    openedItemIds?.includes(item?.data?.id) && item?.data?.components?.map((component, componentIndex) => {
                                        return (
                                            <div key={componentIndex} className="group-container">
                                                <div className="progress-section">
                                                    <div className="component-title">
                                                        {component?.enabled ? <span className={clsx('tick', {
                                                            ['cross']: component?.status !== statusTypes.operational.key
                                                        })}>
                                                            {(component?.status === statusTypes.operational.key ? <TickIcon width={13} height={13} /> : <CrossIcon width={13} height={13} />) }
                                                        </span>
                                                            :
                                                            <span className='disabled-component'>
                                                                <ExclamationIcon width={20} height={20} fill="#64748B"/>
                                                                <p className="disabled-component-text">{text.disabled}</p>
                                                            </span> }
                                                        <div className="component-header">
                                                            <p className="component-header-text">{component?.name}</p>
                                                            <div className="component-log-info">
                                                                <InfoIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} />
                                                                <p className="component-log-info-desc">{component?.description}</p>
                                                            </div> 
                                                        </div>                                                       
                                                    </div>
                                                    <div className="progress-bars">
                                                        {returnStatisticsData(component?.id)?.map((statisticData, statisticIndex) => {
                                                            return statisticData?.status === null ?
                                                                <div key={statisticIndex} className="progress-bar null">
                                                                    <div className="no-data">
                                                                        <h5>{moment(statisticData?.date).format('MMM DD, YYYY')}</h5>
                                                                        <span>{text.no_data}</span>
                                                                    </div>
                                                                </div> : statisticData?.status !== statusTypes.operational.key ?
                                                                    <div key={statisticIndex} className="progress-bar non-operational">
                                                                        <div className="detect-incident">
                                                                            <span className="status">
                                                                                <span className="status-icon cross">
                                                                                    <CrossIcon width={16} height={16} />
                                                                                </span>
                                                                                <span>{text.incident}</span>
                                                                            </span>
                                                                            <h5>{moment(statisticData?.date).format('MMM DD, YYYY')}</h5>
                                                                            <p className="incident-desc">{statisticData?.description}</p>
                                                                            {!!statisticData?.duration && <span className="duration">{`${text.duration}: ${formatMinutes(statisticData.duration)}`}</span>}
                                                                        </div>
                                                                    </div> :
                                                                    <div key={statisticIndex} className="progress-bar">
                                                                        <div className="no-incident">
                                                                            <span className="status">
                                                                                <span className="status-icon tick">
                                                                                    <TickIcon width={16} height={16} />
                                                                                </span>
                                                                                <span>{text.operational}</span>
                                                                            </span>
                                                                            <h5>{moment(statisticData?.date).format('MMM DD, YYYY')}</h5>
                                                                            <span className="no-incident-text">{text.no_incidents}</span>
                                                                        </div>
                                                                    </div>
                                                        })}
                                                    </div>
                                                    <div className="time-interval">
                                                        <h4><span>{returnDaysText()}</span> </h4>
                                                        <div className="uptime">
                                                            <h4><span>{`${groupComponentsStatisticsData?.find(groupComponent => groupComponent?.id === component?.id)?.data?.uptime?.up?.toFixed(2)}% `}</span>{text.uptime}</h4>
                                                        </div>
                                                        <h4>{text.today}</h4>
                                                    </div>
                                                    {getOpenIncidents(component?.id)?.length && (
                                                        <div className="open-incident component">
                                                            <div className="component-open-incident-head">
                                                                <span className="component-icon">
                                                                    <InfoIcon stroke='#fff' width={16} height={16} />
                                                                </span>
                                                                <span className="component-text">{text.incident_details}</span>
                                                            </div>
                                                            <span className="open-incident-desc">
                                                                {getOpenIncidents(component?.id)?.[0]?.description}
                                                            </span>
                                                            <span className="component-open-incident-started">
                                                                {moment(getOpenIncidents(component?.id)?.[0]?.started_at).format('MMM DD, YYYY')}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })}
                </div>
                <div className="go-to">
                    <Link to={routes.incident_histories.path}>
                        <h3>{text.got_to}</h3>
                        <ArrowRightIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} />
                    </Link>
                </div>
            </main>
        )
}

export default StatusPage
